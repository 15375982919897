/* eslint-disable consistent-return */
/* eslint-disable require-atomic-updates */
/* eslint no-throw-literal: off */
import * as logger from 'loglevel';
import { applySnapshot, flow, getSnapshot, types as t } from 'mobx-state-tree';
import i18next, { getCurrentLocale } from 'i18n';
import cloneDeep from 'lodash/cloneDeep';
import { LessonEdit } from 'state/models/LessonEdit';

// TODO : move to CourseEdit modal
import { changeLanguageMeta } from 'helpers/Helpers';
import { convertSnapshot } from './api/LmsApi';
import { LessonResult } from './modelsEwapi/LessonContainer';
import { Authors } from './modelsEwapi/Author';

const log = logger.getLogger('RootStore');
log.setDefaultLevel('info');

export const StoreFetch = t
  .model()
  // PATCH + POST ACTIONS
  .actions((self) => ({
    patchpostData: (method, entity, data) => {
      if (method === 'post') return self.postData(entity, data);
      return self.patchData(data);
    },

    patchData: flow(function* patchData(entity, data) {
      try {
        if (entity.type === 'lesson_result') applySnapshot(entity, convertSnapshot.lesson_result(data)); // Direct rerender after submit in question
        const packagecourses = self.courses && self.courses.courses.map((courses) => courses.course_id);
        const response = yield self.lmsApi.patchpost(data);
        if (response.favorite_courses) {
          const favorites = response.favorite_courses.filter((course) => packagecourses.includes(course.target_id));
          if (favorites) {
            self.favorites = { ...response, favorite_courses: favorites };
            self.favoriteIds = response.favorite_courses.map((course) => course.uuid);
            return self.favorites;
          }
          self.favorites = null;
        }
        if (response.liked_courses) {
          const liked = response.liked_courses.filter((course) => packagecourses.includes(course.target_id));
          if (liked) {
            self.likes = { ...response, liked_courses: liked };
            self.likedIds = response.liked_courses.map((course) => course.uuid);
            return self.likes;
          }
          self.likes = null;
        }
        if (response.hidden_courses) {
          const hidden = response.hidden_courses.filter((course) => packagecourses.includes(course.target_id));
          if (hidden) {
            self.hidden = { ...response, hidden_courses: hidden };
            self.hiddenIds = response.hidden_courses.map((course) => course.uuid);
            return self.hidden;
          }
          self.hidden = null;
          return self.hidden;
        }
        if (response.type === 'user') {
          self.profile = { ...response, relationships: self.profile.relationships };
          return self.profile;
        }
        applySnapshot(entity, response);
        log.debug(`Patch ${entity.type}:`, response);
        return response;
      } catch (e) {
        self.error = e;
        self.state = 'error';
        self.errorShown = false;
        log.error(e);
        throw e;
      }
    }),

    postData: flow(function* patchData(data) {
      try {
        const packagecourses = self.courses.courses.map((courses) => courses.course_id);
        const response = yield self.lmsApi.patchpost(data);
        if (response.favorite_courses) {
          const favorites = response.favorite_courses.filter((course) => packagecourses.includes(course.target_id));
          if (favorites) {
            self.favorites = { ...response, favorite_courses: favorites };
            self.favoriteIds = response.favorite_courses.map((course) => course.uuid);
            return self.favorites;
          }
          self.favorites = null;
          return self.favorites;
        }
        if (response.liked_courses) {
          const liked = response.liked_courses.filter((course) => packagecourses.includes(course.target_id));
          if (liked) {
            self.likes = { ...response, liked_courses: liked };
            self.likedIds = response.liked_courses.map((course) => course.uuid);
            return self.likes;
          }
          self.likes = null;
          return self.likes;
        }
        if (response.hidden_courses) {
          const hidden = response.hidden_courses.filter((course) => packagecourses.includes(course.target_id));
          if (hidden) {
            self.hidden = { ...response, hidden_courses: hidden };
            self.hiddenIds = response.hidden_courses.map((course) => course.uuid);
            return self.hidden;
          }
          self.hidden = null;
          return self.hidden;
        }
        log.debug(`Post ${data.type}:`, data);
        return response;
      } catch (e) {
        self.error = e;
        self.state = 'error';
        self.errorShown = false;
        log.error(e);
        return null;
      }
    }),

    deleteData: flow(function* deleteData(data) {
      try {
        data = yield self.lmsApi.deletePost(data);
        return data;
      } catch (e) {
        self.error = e;
        self.state = 'error';
        self.errorShown = false;
        log.error(e);
        return null;
      }
    })
  }))
  // INITIAL FETCHES FOR COURSE OVERVIEW
  .actions((self) => ({
    //
    // ============= FETCH EWAPI STUDENT DATA INCL COURSES ================
    fetchData: flow(function* fetchData(selectedProductId, selectedPackageId) {
      const productID = selectedProductId.toString() || self.selectedProductId;
      const packageID = selectedPackageId.toString() || self.selectedPackageId;

      // Save some data in session storage as we are loosing data on page refresh
      sessionStorage.setItem('selectedProductId', productID);
      sessionStorage.setItem('selectedPackageId', packageID);

      if (self.state === 'pending') return; // wait for running fetch
      self.state = 'pending';
      self.error = null;
      try {
        self.setState('loading');
        const { ewapi: fetched } = yield self.lmsApi.fetchUserData(productID, packageID);
        // Throw new error if ewapi returns error
        if (fetched?.data?.error) {
          self.state = 'init';
          self.hasSubscriptions = false;
          self.state = 'done';
          return fetched;
          // throw new Error(`${fetched?.ewapi?.data?.errorcode} : ${fetched?.ewapi?.data?.error}`);
        }

        self.hasSubscriptions = true;

        //  TODO: refactor if
        if (fetched?.data?.result?.base_product_id) {
          fetched.data.result.product_id = 100;
          fetched.data.result.products_in_subscription = [100];
          fetched.data.subscriptions = self.setEwiseSubscriptions(fetched);
          fetched.data.product = self.setEwiseProduct(fetched);
        }
        //
        self.setLanguage(fetched);
        changeLanguageMeta(fetched?.data?.user?.preferred_langcode || getCurrentLocale());
        //
        self.updateStore(fetched);
        self.setFavorites(fetched);
        self.setLikes(fetched);
        self.setHidden(fetched);
        self.setCourseResults(fetched);
        // TODO: User roles and permissions
        //
        self.setLikedIds(fetched);
        self.setFavoritesIds(fetched);

        if (self.isEwiseUser) {
          self.setSelectedProduct(fetched.data.baseproduct.nid, fetched.data.result.package_id);
        } else {
          self.setSelectedProduct(fetched.data.result.product_id, fetched.data.result.package_id);
        }

        self.specialAccessCourseIds = [];
        if (self.login.hasSpecialAccess) {
          const specialAccessResult = yield self.lmsApi.fetchSpecialAccessCourses();
          self.specialAccessCourseIds = specialAccessResult?.data?.courses?.map((course) => course.course_id) || [];
        }

        //
        setTimeout(() => {
          self.setState('done');
        }, 1000);
        // eslint-disable-next-line consistent-return
        return self;
      } catch (e) {
        self.error = e;
        self.state = 'error';
        // if (self.error.status === 401) yield self.logout();
        if (e.modelErrors) e.modelErrors.forEach((error) => log.warn(error));
        throw e;
      }
    }),
    //
    // ============= FETCH STUDENT DATA ONLY ================
    fetchUser: flow(function* fetchUser() {
      try {
        const fetched = yield self.lmsApi.fetchUser(self.login.uuid);
        const data =
          fetched?.data?.included.find(
            (entity) => entity.type === 'file--file' && entity.id === fetched.data.data[0].relationships.user_picture.data?.id
          ) || null;
        const professions =
          fetched?.data?.included.find(
            (entity) => entity.type === 'profession' && entity.id === fetched.data.data[0].relationships.professions.data[0]?.id
          ) || null;
        const userProfessions = fetched?.data?.included.filter((bp) => bp.type === 'profession') || [];
        self.profile = { ...fetched.data.data[0], relationships: { user_picture: { data }, professions } };
        self.profile.userProfessions = userProfessions;
        return self.profile;
      } catch (e) {
        return e;
      }
    }),
    //
    // ============= FETCH SPECIALACCESS COURSES ================
    fetchSpecialAccessCourses: flow(function* fetchSpecialAccessCourses() {
      if (self.state === 'pending') return; // wait for running fetch
      self.state = 'pending';
      self.error = null;
      try {
        //
        self.setState('loading');
        //
        const fetched = yield self.lmsApi.fetchSpecialAccessCourses();
        self.state = 'done';
        //
        if (fetched.data.success !== undefined && fetched.data.success === false) {
          self.courses = { courses: [] };
          self.courseResults = { courseResults: [] };
          self.lesson_results = { lessonResults: [] };
          return self.courses;
        }
        self.updateStoreSpecialAccess(fetched);
        self.setCourseResults(fetched);
        self.setLanguage(fetched);
        return self.courses;
      } catch (e) {
        self.error = e;
        self.state = 'error';
        if (e.modelErrors) {
          e.modelErrors.forEach((error) => log.warn(error));
        }
        throw e;
      }
    }),
    // ============= FETCH EWAPI STUDENT DATA INCL COURSES ================
    fetchSearchData: flow(function* fetchSearchData(selectedSearchParam, selectedBaseProductId, selectedSearchAttempt) {
      const searchParam = selectedSearchParam.toString() || self.searchParam;
      const baseProductId = selectedBaseProductId.toString() || self.baseProduct.nid;
      const searchAttempt = parseInt(selectedSearchAttempt) || 0;

      if (self.state === 'pending') return; // wait for running fetch
      self.state = 'pending';
      self.error = null;
      try {
        self.setState('loading');
        const fetched = yield self.lmsApi.fetchSearchResults(searchParam, baseProductId, searchAttempt);
        // Throw new error if ewapi returns error
        if (fetched?.data?.error) {
          self.state = 'init';
          self.hasSubscriptions = false;
          self.state = 'done';
          return fetched;
        }

        self.hasSubscriptions = true;

        const { result, meta } = fetched.data;

        self.searchCoursesFound = meta?.total ?? 0;
        self.searchSuggestedParam = result?.suggested_param ?? ''; // Given by AI
        self.searchNextAttempt = result?.attempt ?? 1; // Next attempt number
        self.searchIsRandom = result?.random ?? false; // Random search

        if (result?.is_ewise === true) {
          self.product = self.setEwiseProduct(fetched);
        } else {
          self.product = JSON.parse(sessionStorage.getItem('selectedProduct'));
        }

        // Get some data from local session storage
        self.selectedPackageId = parseInt(sessionStorage.getItem('selectedPackageId'));
        self.selectedProductId = parseInt(sessionStorage.getItem('selectedProductId'));

        if (meta.total === 0) {
          self.state = 'init';
          self.hasSubscriptions = true;
          self.courses = { courses: [] };
          self.courseResults = { courseResults: [] };
          self.lesson_results = { lessonResults: [] };
          self.authors = Authors.create({ authorList: [] });
          self.state = 'done';
          return fetched;
        }

        self.updateSearchResults(fetched);
        self.setFavorites(fetched);
        self.setLikes(fetched);
        self.setHidden(fetched);
        self.setCourseResults(fetched);
        self.setLikedIds(fetched);
        self.setFavoritesIds(fetched);

        //
        self.state = 'done';
        // eslint-disable-next-line consistent-return
        return self;
      } catch (e) {
        self.error = e;
        self.state = 'error';
        if (e.modelErrors) e.modelErrors.forEach((error) => log.warn(error));
        throw e;
      }
    })
  }))
  // OTHER ACTIONS
  .actions((self) => ({
    // UPDATE STORE WITH FETCHED RESULT
    updateStore(fetched) {
      self.specialAccess = false;
      self.courses = null;
      self.validUser = true;
      self.user = fetched.data.user;
      self.profession = fetched.data.profession;
      self.categories = fetched.data.profession.categories;
      self.categories2 = fetched.data.profession.categories2;
      self.courses = { courses: fetched.data.courses.sort((a, b) => a.course_title.localeCompare(b.course_title)) };
      //
      self.subscriptions = { subscriptions: fetched.data.subscriptions };
      self.products = fetched.data.result.products_in_subscription;
      self.baseProduct = fetched.data.baseproduct;
      self.packages = fetched.data.baseproduct.packages;

      self.authors = Authors.create({ authorList: fetched.data.authors.sort((a, b) => a.last_name.localeCompare(b.last_name)) });
      self.product = fetched.data.product;

      self.totalCompletedCourses = fetched.meta.user_courses_finished;

      sessionStorage.setItem('selectedProduct', JSON.stringify(self.product));
    },
    updateStoreSpecialAccess(fetched) {
      // self.specialAccess = fetched.data;
      self.specialAccess = true;
      // UPDATE STORE WITH FETCHED RESULT
      fetched.data.user.uid = fetched.data.user.id;
      self.user = fetched.data.user;
      self.courses = null;
      self.validUser = true;
      // eslint-disable-next-line prefer-destructuring, prefer-destructuring
      self.profession = fetched.data.professions[0];
      self.categories = fetched.data.professions[0].categories;
      self.categories2 = fetched.data.professions[0].categories2;

      self.favorites = null;

      self.courses = null;
      self.courses = { courses: fetched.data.courses.sort((a, b) => a.course_title.localeCompare(b.course_title)) };
      self.specialAccessCourseIds = fetched?.data?.courses?.map((course) => course.course_id) || [];

      self.authors = Authors.create({ authorList: fetched.data.authors.sort((a, b) => a.last_name.localeCompare(b.last_name)) });
      self.subscriptions = { subscriptions: fetched.data.subscriptions };
    },
    // Update categories
    updateSearchResults(fetched) {
      self.courses = null;
      self.courses = { courses: fetched.data.courses };
      self.authors = Authors.create({
        authorList: [...(fetched.data.authors || []), ...(fetched.data.reviewers || [])].sort((a, b) =>
          a.last_name.localeCompare(b.last_name)
        )
      });
      self.subscriptions = { subscriptions: fetched.data.subscriptions };
      self.packages = fetched.data.baseproduct.packages;
      self.baseProduct = fetched.data.baseproduct;
      self.products = fetched.data.products_in_subscription;
    },
    // SET LANGUAGE
    setLanguage(fetched) {
      // set app language
      const userLanguage = fetched.data.user.preferred_langcode;
      if (userLanguage && userLanguage !== i18next.language) {
        i18next.changeLanguage(userLanguage, (err) => {
          if (err) return log.error('something went wrong loading', err);
          return log.info('language changed');
        });
      }
    },

    // SET FAVORITES
    setFavorites(fetched) {
      if (fetched.data.favorites) {
        const packagecourses = self.courses.courses.map((courses) => courses.course_id);
        const favorites = fetched?.data?.favorites.favorite_courses?.filter((course) => packagecourses.includes(course.target_id));
        self.favorites = favorites ? { ...fetched.data.favorites, favorite_courses: favorites } : null;
      }
    },
    // SET LIKES
    setLikes(fetched) {
      if (fetched.data.likes) {
        const packagecourses = self.courses.courses.map((courses) => courses.course_id);
        const favorites = fetched?.data?.likes?.liked_courses?.filter((course) => packagecourses.includes(course.target_id));
        self.likes = favorites ? { ...fetched.data.likes, liked_courses: favorites } : null;
      }
    },
    setHidden(fetched) {
      if (fetched.data.hidden) {
        const packagecourses = self.courses.courses.map((courses) => courses.course_id);
        const hidden = fetched?.data?.hidden?.hidden_courses?.filter((course) => packagecourses.includes(course.target_id));
        if (hidden) {
          self.hidden = { ...fetched.data.hidden, hidden_courses: hidden };
          self.hiddenIds = hidden.map((course) => course.uuid);
        } else {
          self.hidden = null;
        }
      }
    },
    setLikedIds(fetched) {
      self.likedIds = fetched?.data?.likes && fetched?.data?.likes?.liked_courses?.map((course) => course.uuid);
      // self.likedIds = fetched?.data?.likes.liked_courses.map((course) => course.uuid);
    },
    setFavoritesIds(fetched) {
      self.favoriteIds = fetched?.data?.favorites && fetched?.data?.favorites?.favorite_courses?.map((course) => course.uuid);
      // self.favoriteIds = fetched?.data?.favorites?.favorite_courses.map((course) => course.uuid);
    },
    // SET COURSE RESULTS
    setCourseResults(fetched) {
      if (fetched.data.course_results) {
        const packagecourses = self.courses.courses.map((courses) => courses.course_id);
        const started = fetched.data.course_results.filter((course) => packagecourses.includes(course.course_id));
        self.courseResults = { courseResults: started };
        const startedIds = started.map((courses) => courses.id);
        const filteredLessonResults = fetched.data.lesson_results.filter((lesson) => startedIds.includes(lesson.course_result));
        const lessonResults = LessonResult.create(filteredLessonResults);
        self.lesson_results = { lessonResults: getSnapshot(lessonResults) };
      }
    },
    // SET COURSE RESULTS
    setCourseResultsSpecialAccess(fetched) {
      if (fetched.data.course_results) {
        const packagecourses = self.courses.courses.map((courses) => courses.course_id);
        const started = fetched.data.course_results.filter((course) => packagecourses.includes(course.course_id));
        self.courseResults = { courseResults: started };
        const startedIds = started.map((courses) => courses.id);
        const filteredLessonResults = fetched.data.lesson_results.filter((lesson) => startedIds.includes(lesson.course_result));
        const lessonResults = LessonResult.create(filteredLessonResults);
        self.lesson_results = { lessonResults: getSnapshot(lessonResults) };
      }
    },
    setEwiseSubscriptions(fetched) {
      const curDate = new Date();
      const newDate = new Date(curDate.setFullYear(curDate.getFullYear() + 1));
      const subEndValue = newDate.toJSON().slice(0, 10);
      return [
        {
          nid: 8402,
          uuid: 'ce4599ae-8802-46f3-abda-40edb4144477',
          product: 100,
          base_product_title: `EWISE ${fetched.data.baseproduct.title}`,
          maximum_points: 1000,
          valid_period: [{ value: '2022-09-12', end_value: subEndValue }]
        }
      ];
    },
    setEwiseProduct(fetched) {
      return {
        nid: 100,
        uuid: 'b9b056a8-4745-4b88-b9ea-7916ff8eb324',
        title: fetched.data.baseproduct.title,
        free_product: 0
      };
    },

    // ============== FETCH COURSES ====================
    //   fetches all related course_container data: courses, lesson_container
    //   + FETCH USER COURSE RESULTS
    fetchCourses: flow(function* fetchCourses() {
      /**
       * Find base_product in included:
       * get selected package id in base_product : base_product -> relationships -> packages[0].id
       * included.find package with if from base_product
       * package -> relationships -> course_container.id
       * fetched = yield fetchCourseContainerData(id);
       */
      // wait for running fetch

      self.error = null;
      let courseContainerId = false;
      const baseProduct = self.included.filter((bp) => bp.type === 'base_product');
      const baseProductJson = baseProduct.filter((on) => on.attributes.unique_name === self.product.attributes.unique_name)[0];
      const firstPackageId = baseProductJson.relationships.packages.data[self.selectedPackageId].id.id;
      const firstPackage = self.included.find((pkg) => pkg.id === firstPackageId);
      const firstPackageJson = JSON.parse(JSON.stringify(firstPackage));
      courseContainerId = firstPackageJson.relationships.course_container.data.id;
      // FETCH COURSE_CONTAINER INCLUDED COURSES, lESSON_CONTAINER
      try {
        if (courseContainerId && !self.clickedTabs.includes(self.selectedPackageId)) {
          const fetched = yield self.lmsApi.fetchCourseContainerData(courseContainerId);
          self.mergeIncludes(fetched);
        }
      } catch (e) {
        self.error = e;
        self.state = 'error';
        log.error(e);
        if (e.modelErrors) {
          e.modelErrors.forEach((error) => log.warn(error));
        }
        throw e;
      }

      if (!self.startedCourse) {
        // FETCH THE USER COURSE RESULTS
        self.fetchUserCourseResults();
        // FETCH THE USER COURSE FAVORITES
        self.fetchCourseFavorites();
        self.startedCoursesLoaded(true);
      }
    }),

    // TODO : same function as in fetchCourses [2] Both needed ?
    // Get available course results for loaded courses
    fetchUserCourseResults: flow(function* fetchUserCourseResults() {
      try {
        const responseCourseResults = yield self.lmsApi.fetchCourseResults(self.login.uuid);
        self.certificates = responseCourseResults.data;
      } catch (e) {
        self.error = e;
        self.state = 'error';
        log.error(e);
        if (e.modelErrors) {
          e.modelErrors.forEach((error) => log.warn(error));
        }
        throw e;
      }
    }),

    fetchCourseFavorites: flow(function* fetchCourseFavorites() {
      try {
        const responseCourseResults = yield self.lmsApi.fetchCourseFavorites(self.user.id);
        if (responseCourseResults) {
          self.mergeIncludes(responseCourseResults.data);
          self.mergeIncludes(responseCourseResults.included);
        }
      } catch (e) {
        self.error = e;
        self.state = 'error';
        log.error(e);
        if (e.modelErrors) {
          e.modelErrors.forEach((error) => log.warn(error));
        }
        throw e;
      }
    }),

    fetchBanner: flow(function* fetchBanner(productId) {
      try {
        const fetched = yield self.lmsApi.fetchBanners(productId);
        const banners = fetched.data.data.map((bannersData) => {
          const backgroundImageID = bannersData.relationships.background_image.data.id;
          const backgroundImage = fetched.data.included.find((entity) => entity.type === 'file--file' && entity.id === backgroundImageID);
          return { ...bannersData, relationships: { background_image: { data: backgroundImage } } };
        });
        self.banners = banners;
        return self.banners;
      } catch (e) {
        return e;
      }
    }),

    fetchVtbOverview: flow(function* fetchVtbOverview(query) {
      try {
        const fetchedVtb = yield self.lmsApi.fetchVtbOverview(query);
        const vtbevents = fetchedVtb.data.map((eventsData) => ({ ...eventsData }));
        const vtblocations = fetchedVtb.included
          ? fetchedVtb.included.filter((includedEntity) => includedEntity.type === 'event_location')
          : [];
        const vtbcategories = fetchedVtb.included
          ? fetchedVtb.included.filter((includedEntity) => includedEntity.type === 'event_type')
          : [];
        self.vtbevents = vtbevents;
        self.vtblocations = vtblocations;
        self.vtbcategories = vtbcategories;
      } catch (e) {
        return e;
      }
    })
  }))
  // WIDGETS
  .actions((self) => ({
    // .LESSON:get WIDGETS
    getLessonWidgets: flow(function* getLessonWidgets(lessonId) {
      if (self.lessonEditState === 'pending') return; // wait for running fetch
      self.lessonEditState = 'pending';
      self.error = null;
      try {
        const fetched = yield self.lmsApi.getLessonEdit(lessonId);

        const objLessonEdit = {
          tagdoc: fetched.included ? fetched.included.find((includedEntity) => includedEntity.type === 'file') : null,
          widgets: fetched.included ? fetched.included.filter((includedEntity) => includedEntity.type === 'question_widget') : [],
          lesson_content: fetched.data
        };
        self.lessonEdit = LessonEdit.create(objLessonEdit);
        self.lessonEditState = 'done';
      } catch (e) {
        self.error = e;
        self.state = 'error';
        self.errorShown = false;
        log.error(e);
        // eslint-disable-next-line consistent-return
        return null;
      }
    }),

    // .LESSON:post WIDGET
    postWidget: flow(function* postWidget(questionWidget) {
      // eslint-disable-next-line camelcase
      const { lesson_content } = self.lessonEdit;
      const newLessonContentValue = cloneDeep(getSnapshot(self.lessonEdit.lesson_content));

      newLessonContentValue.relationships.lesson_content_widgets.data.push({ type: 'question_widget', id: '{{widget.body@$.data.id}}' });
      try {
        const fetched = yield self.lmsApi.createWidget(questionWidget, newLessonContentValue);
        const cleanResponse = validateFetchedObjects(fetched);
        applySnapshot(lesson_content, cleanResponse.content);
        self.lessonEdit.update(cleanResponse.widget, 'widgets');
        return fetched;
      } catch (e) {
        self.error = e;
        self.state = 'error';
        self.errorShown = false;
        log.error(e);
        return null;
      }
    })
  }));

// create CLEAN RESPONSE OBJECT from SUBREQUEST
function validateFetchedObjects(fetched) {
  const cleanResponse = {};
  Object.keys(fetched).forEach((key) => {
    const cleanKey = key.split('#');
    if (!fetched[key].errors) cleanResponse[cleanKey[0]] = fetched[key].data;
  });

  return cleanResponse;
}
