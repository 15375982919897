/* eslint-disable require-atomic-updates, no-nested-ternary */
/* eslint no-throw-literal: off */
import { reaction } from 'mobx';
import { createRouterState, RouterStore, HistoryAdapter } from 'mobx-state-router';
import { types as t, flow, destroy, getSnapshot, onSnapshot, getEnv } from 'mobx-state-tree';
// import {getType, resolveIdentifier,  } from 'mobx-state-tree';
import * as logger from 'loglevel';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import { changeMetaRoute, trackGaPageViews } from 'helpers/Helpers';
import { StoreFetch } from './RootStoreFetch';
import { startLmsApiClient } from './api/LmsApi';

// MODELS
import { Banner } from './models/Banner';
import { CourseEdit } from './models/CourseEdit';
import { LessonEdit } from './models/LessonEdit';
import { AuthorEdit } from './models/AuthorEdit';
import { CourseCopy } from './models/CourseCopy';
// import { LessonPlay } from './models/LessonPlay';

// MODELS EWAPI
import { User } from './modelsEwapi/User';
import { Likes } from './modelsEwapi/Likes';
import { Hidden } from './modelsEwapi/Hidden';
import { Authors } from './modelsEwapi/Author';
import { Courses } from './modelsEwapi/Course';
import { Product } from './modelsEwapi/Product';
import { Profile } from './modelsEwapi/Profile';
import { Packages } from './modelsEwapi/Package';
import { Favorites } from './modelsEwapi/Favorites';
import { BaseProduct } from './modelsEwapi/BaseProduct';
import { Subscriptions } from './modelsEwapi/Subscription';
import { CourseResults } from './modelsEwapi/CourseResults';
import { LessonResults } from './modelsEwapi/LessonContainer';
import { Category, Profession } from './modelsEwapi/Profession';
import { LmsDashboard } from './models/Lms/LmsDashboard';

import { CurriculumStore } from '../pages/curriculum/store';

const log = logger.getLogger('RootStore');
log.setDefaultLevel('info');

// live routes in the root
const RouterStateModel = t.model({
  routeName: '',
  // The unknown properties of the objects params and queryParams kan only be accessed by toJSON/getSnapshot
  params: t.map(t.string),
  queryParams: t.map(t.string)
});

const FirstStore = t
  .model({
    validUser: false,
    maintenance: false,
    packages: Packages,
    selectedProductId: 0,
    selectedPackageId: 0,
    user: t.maybeNull(User),
    likes: t.maybeNull(Likes),
    hidden: t.maybeNull(Hidden),
    product: t.maybeNull(Product),
    courses: t.maybeNull(Courses),
    specialAccessCourseIds: t.maybeNull(t.array(t.number)),
    categories: t.array(Category),
    authors: t.maybeNull(Authors),
    profile: t.maybeNull(Profile),
    // product: t.maybeNull(Product),
    favorites: t.maybeNull(Favorites),
    professions: t.maybeNull(Profession),
    baseProduct: t.maybeNull(BaseProduct),
    banners: t.maybeNull(t.array(Banner)),
    products: t.maybeNull(t.array(t.number)),
    subscriptions: t.maybeNull(Subscriptions),
    courseResults: t.maybeNull(CourseResults),
    lesson_results: t.maybeNull(LessonResults),
    categories2: t.maybeNull(t.array(Category)),
    // invalidProducts: t.array(t.reference(BaseProduct)),
    // invalidPackages: t.array(t.array(t.reference(Package))),
    // marketing_messages: t.array(t.reference(MarketingMessage)),
    state: t.optional(t.enumeration('State', ['init', 'pending', 'done', 'error', 'loading']), 'init'),
    // faq: t.array(Faq),
    routerState: t.optional(RouterStateModel, { routeName: '', params: {}, queryParams: {} }),
    LmsDashboard,
    totalCompletedCourses: t.optional(t.number, 0)
  })
  .volatile(() => ({
    specialAccess: 'init',
    hasSubscriptions: 'init',
    errorPage: {},
    // authors: [],
    modelErrors: [],
    lessonPlay: null,
    errorShown: false,
    browserHistory: [],
    lessonState: 'init',
    permissions: new Map(),
    courseEditState: 'init',
    authorEdit: t.maybeNull(AuthorEdit),
    courseEdit: t.maybeNull(CourseEdit),
    lessonEdit: t.maybeNull(LessonEdit),
    assistantManagement: { title: 'Mijn Account' },
    maxRetries: 2, // TODO: get this from the server
    error: null, // Properties can change and not needed in permanent shapes
    authorEditState: t.optional(t.enumeration(['init', 'pending', 'done', 'error']), 'init'),
    lessonEditState: t.optional(t.enumeration(['init', 'pending', 'done', 'error']), 'init'),
    // professionsState: t.optional(t.enumeration(['init', 'pending', 'done', 'error']), 'init'),
    // professions: t.maybeNull(t.array(Profession)),
    prevTab: 0,
    lessons: [],
    dialog: false,
    resetToken: '',
    selectedNid: '',
    minDrawer: true,
    toastStates: [],
    clickedTabs: [],
    courseDetails: [],
    openDrawer: false,
    scrollPosition: 0,
    ewiseProducts: [],
    // courseCopy: null,
    startedCourse: false,
    loadingCourses: false,
    isToastvisible: false,
    isfilterVisible: true,
    courseHeaderTabs: 'All',
    // courseFavorite: t.number,
    assistantNavbar: 'assistant',
    courseCopy: t.maybeNull(CourseCopy),
    courseoverview: 3, // TODO: use enumeration for this
    login: { userName: '', scope: [], prefBaseProduct: null, uuid: '' },
    lmsAccess: null,
    lmsStudentDetail: {},
    lmsRecordsPerPage: 50,
    passwordForced: false,
    noSidebar: false,
    searchParam: '',
    searchBaseParam: 0,
    searchingCourses: false,
    searchCoursesFound: 0,
    searchSuggestedParam: '',
    searchNextAttempt: 1,
    searchIsRandom: false,
    showDependingPopup: false,
    dependingCourse: null,
    bannerProducts: []
  }))
  .views((self) =>
    // const tmpRouterState = null;
    ({
      get params() {
        return getSnapshot(self.routerState.params);
      }, // Shortcut to get to uri params of the active URL
      findCourseByNid(nid) {
        return self.courses && self.courses.courses.find((course) => course.course_id === nid);
      },
      get defaultCourseGridImage() {
        return 'https://www.e-wise.nl/sites/default/files/inline-images/fp-po320.jpg';
      },
      avatarUrl() {
        if (self?.user?.user_picture) return self.user.user_picture.replace('http://', 'https://');
        if (self.login.profilePicture) return self.login.profilePicture.replace('http://', 'https://');
        return null;
      },
      getCourses() {
        return self.courses.courses.map((course) => ({ courseId: course.course_id, courseTitle: course.course_title }));
      },
      get lmsApi() {
        return startLmsApiClient();
      },

      get oauth() {
        return window.clientInstance;
      }
    })
  )
  // ========== Packages ==========
  .views((self) => ({
    get selectedPackageTitle() {
      return (
        (self.packages.length > 0 &&
          self.selectedPackageId !== 0 &&
          self?.packages?.find((findPackage) => findPackage.nid === self?.selectedPackageId).tab_label) ||
        ''
      );
    },
    get selectedPackage() {
      return self.packages.find((findPackage) => findPackage.nid === self.selectedPackageId);
    }
  }))

  // ========== LOGIN, SHOW/HIDE ELEMENTS based on role/permissions ==========
  .views((self) => ({
    get label() {
      const hostname = window.location.host;
      if (hostname.includes('e-wise')) return 'ewise';
      if (hostname.includes('pe-academy')) return 'pe';
      if (hostname.includes('po-online')) return 'po';
      if (hostname.includes('cme-online')) return 'cme';
      return 'pe';
    },
    get isAdministrator() {
      return !!self.login.scope.includes('administrator');
    },
    get canSeeAnswers() {
      return self.isAdministrator;
    },
    get isEwiseUser() {
      return !!self.login.scope.includes('e_wise');
    },
    get isMarketing() {
      return !!self.login.scope.includes('marketing');
    },
    get canCreateXml() {
      if (self.isAdministrator) return true;
      if (self.isMarketing) return true;
      return false;
    },
    get hasPreferredBaseproduct() {
      return !!self.login.prefBaseProduct;
    },
    get preferredBaseproduct() {
      return self.login.prefBaseProduct;
    },
    get canPrintCourse() {
      if (self.isAdministrator) return true;
      if (self.login.scope.includes('redactie')) return true;
      if (self.login.hasSpecialAccess) return true;
      return false;
    },
    get canEditCourse() {
      if (self.isAdministrator) return true;
      if (self.login.scope.includes('redactie')) return true;
      if (self.isMarketing) return true;
      return false;
    },
    get canAddAnswerPdfToCourse() {
      const allowedPackages = ['huisarts', 'ouderengeneeskunde', 'verpleegkundig-specialist'];
      if (self.isAdministrator) return true;
      if (self.login.scope.includes('redactie') && allowedPackages.includes(self.params.package)) return true;
      return false;
    },
    get canGotoAdminPanel() {
      if (self.isAdministrator) return true;
      if (self.isMarketing) return true;
      if (self.login.scope.includes('sales')) return true;
      if (self.login.scope.includes('redactie')) return true;
      return false;
    },
    get ewiseUserUuid() {
      return self.login.uuid;
    },
    get showMenuMyCompany() {
      if (self.showMenuItemLms) return true;
      if (self.showMenuAssistant) return true;
      return false;
    },
    get showMenuItemLms() {
      if (self.isAdministrator || self.isEwiseUser) return true;
      if (self.login.scope.includes('customer_report')) return true;
      return false;
    },
    get showMenuAssistant() {
      if (self.isEwiseUser) return false;
      if (self.isAdministrator) return false;
      if (self.login.scope.includes('customer_manage')) return true;
      return false;
    },
    get showMenuVTB() {
      return false;
    },
    get showMenuPortfolio() {
      return self.baseProduct?.title?.toLowerCase() === 'accountant';
    },
    get showBtnSubscribe() {
      return self.isDemoProduct;
      // return false;
    },
    get isDemoProduct() {
      return self?.baseProduct?.title.toLowerCase().includes('proef') || false;
    },
    get showNotificationsIcon() {
      return false;
    }
  }))

  // DEFAULT ACTIONS
  .actions((self) => ({
    afterCreate() {
      // Constructor
      log.debug('Starting new store');

      //
      //
      self.setLoginBydDecodeJWT();

      // const tokenUser = self.oauth.tokenInformation.access_token;
      // const tokenDecode = self.decodeJWT(tokenUser);
      // const tokenPayload = tokenDecode.payload;
      // self.login.userName = `${tokenPayload.first_name} ${tokenPayload.last_name}`;
      // self.login.scope = tokenPayload.scope;
      // self.login.prefBaseProduct = tokenPayload.preferred_base_product;
      // self.login.uuid = tokenPayload.sub_uuid;
      //
      //
      //
      // const roles = self.included.filter((includedEntity) => includedEntity.type === 'user_role');
      // if (roles) {
      //   roles.forEach((role) => role.attributes.permissions.map((permission) => self.permissions.set(permission, true)));
      // }

      // Initialize routing
      if (!getEnv(self).routes) return; // Only applicable if within Cypress instantiation
      self.routerStore = new RouterStore(getEnv(self).routes, createRouterState('notFound'), { store: self });

      //
      //
      const history = createBrowserHistory({
        basename: process.env.PUBLIC_URL
      });
      self.history = new HistoryAdapter(self.routerStore, history);
      self.history.observeRouterStateChanges(); // Observe history changes
      // navigate to intialRouteName
      if (getEnv(self).intitialRouteName) {
        history.push(getEnv(self).intitialRouteName);
      }
      // if (self.routerState.routeName) self.routerStore.hydrate(getSnapshot(self.routerState)); // Initialize url / routing if starting from a snapshot
      setTimeout(() => {
        // Give routerStore a chance to initialize
        if (!self.routerState.routeName) self.setRouterState(JSON.parse(JSON.stringify(self.routerStore.routerState))); // first time initialize
        reaction(
          () => self.routerStore.routerState,
          (routerState) => {
            // Keep routState changes synced
            const state = JSON.stringify(routerState);
            if (!self.routerState.routeName || JSON.stringify(getSnapshot(self.routerState)) !== state) {
              self.setRouteIsEqual(true); // for preventing bounce back by onSnapshot
              self.setRouterState(JSON.parse(state));
              changeMetaRoute(self.routerStore);
              trackGaPageViews();
              self.setOverviewType(self.getOverviewType());
            }
          }
        );
        onSnapshot(self.routerState, () => {
          // When rootstore.routerState changes by something other then routerStore
          if (self.routeIsEqual || !self.routerState.routeName) self.setRouteIsEqual(false);
          else self.routerStore.hydrate(getSnapshot(self.routerState)); // Change the routerStore (url)
        });
      }, 0);
    },

    addToBrowserHistory(link) {
      if (typeof link === 'string') return;
      if (self.browserHistory.length === 0) {
        self.browserHistory.push(link);
      } else {
        if (link.queryParams && _isEmpty(link.queryParams)) delete link.queryParams;
        const previous = self.browserHistory[self.browserHistory.length - 1];
        if (previous.routeName === 'lessonPlay' && link.routeName === 'lessonPlay') return; // don't keep lessonplay history
        const prevLink = typeof previous === 'string' ? previous : JSON.stringify(previous);
        const newLink = typeof link === 'string' ? link : JSON.stringify(link);
        if (prevLink !== newLink) self.browserHistory.push(link);
      }
    },
    setLoginBydDecodeJWT() {
      const accessToken = self.oauth.tokenInformation.access_token;
      const base64Url = accessToken.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join('')
      );

      const payload = JSON.parse(jsonPayload);
      self.login.isImpersonation = self.oauth.tokenInformation.isImpersonation || false;

      self.login.userName = `${payload.first_name} ${payload.last_name}`;
      self.login.scope = payload.scope;
      self.login.prefBaseProduct = self.getPrefProduct() > 0 ? self.getPrefProduct() : payload.preferred_base_product; // On browser refresh login payload is still the same
      self.login.uuid = payload.sub_uuid;
      self.login.profilePicture = payload?.profile_picture;
      self.login.hasSpecialAccess = payload?.has_special_access;
      self.login.gdpr = payload.accepted_gdpr;
      self.login.isVtbTeacher = payload?.is_vtb_teacher ? 1 : 0;
      self.login.dlxGate = payload?.is_lti_deeplinking ? 1 : 0; // Used for LTI deeplinking, using a more cryptic name to prevent misuse and security
      self.login.X4gJ2Kq = payload?.lti_user ? 1 : 0; // Used for LTI sessions in the frontend, using a more cryptic name to prevent misuse and security
    },

    setState(state) {
      self.state = state;
      return self.state;
    },

    setScrollingPosition(y) {
      self.scrollPosition = y;
      return self.scrollPosition;
    },

    setRouteIsEqual(state) {
      self.routeIsEqual = state;
    },

    setLessonPlay(lessonPlay = null) {
      self.lessonPlay = lessonPlay;
    },

    setLessonState(state, error) {
      self.lessonState = state;
      self.error = error;
    },

    setResetToken(token) {
      self.resetToken = token;
    },

    setNavbar(tab) {
      self.assistantNavbar = tab;
    },

    setStoreValue(key, value) {
      self[key] = value;
      return value;
    },

    /**
     * Used to show/hide the left sidebar in mobile view
     *
     * @returns {boolean}
     */
    toggleDrawer() {
      self.openDrawer = !self.openDrawer;
      return self.openDrawer;
    },

    /**
     * Used to minimize/maximize the left sidebar in desktop view
     *
     * @returns {boolean}
     */
    setMinDrawer(open) {
      self.minDrawer = open;
      return self.minDrawer;
    },

    setGdpr() {
      // We use this to set the GDPR popup on first time user enters course introduction page
      sessionStorage.setItem('gdpr', true);
    },

    getGdprAss() {
      // We use this to set the GDPR popup on first time user enters course introduction page
      return localStorage.getItem('gdprAss') || 0;
    },

    setGdprAss() {
      // We use this to set the GDPR popup on first time user enters assistant management page
      localStorage.setItem('gdprAss', 1);
    },

    getOverviewType() {
      // Retrieve the course overview type from local storage
      const type = localStorage.getItem('courseoverviewType');
      // Return the type as a number or default to 3
      return type ? Number(type) : 3;
    },

    setOverviewType(overviewType) {
      // We use this to set course overview type when a customer changes the view
      self.courseoverview = overviewType;
      localStorage.setItem('courseoverviewType', overviewType);
    },

    setPrefProduct(product) {
      // We use this to set the GDPR popup on first time user enters course introduction page
      sessionStorage.setItem('prefBaseProduct', Number(product));
    },

    setLtiSession() {
      // We use this to set the LTI session on first time user enters course
      sessionStorage.setItem('ltiSession', 1);
    },

    getLtiSession() {
      // We use this to set the LTI session on first time user enters course
      return sessionStorage.getItem('ltiSession') || 0;
    },

    isLtiSession() {
      return self.getLtiSession() === 1 || self.getLtiSession() === '1';
    },

    setEwiseBannerProducts(products) {
      self.bannerProducts = self.isEwiseUser || self.isAdministrator ? products : [];
    },

    getPrefProduct() {
      // We use this to set the GDPR popup on first time user enters course introcution page
      const prefSessionBaseProduct = sessionStorage.getItem('prefBaseProduct') || 0;
      return Number(prefSessionBaseProduct);
    },

    getEvaluation() {
      // We use this to get the course evaluation to prevent double evaluations
      return sessionStorage.getItem('courseEvaluation');
    },

    setEvaluation(courseResultUuid) {
      // We use this to set the course evaluation to prevent double evaluations
      sessionStorage.setItem('courseEvaluation', courseResultUuid);
    },

    getPasswordForced() {
      return sessionStorage.getItem('forcePasswordReset');
    },

    setPasswordForced() {
      sessionStorage.setItem('forcePasswordReset', 1);
    },

    setResetPasswordForced() {
      sessionStorage.removeItem('forcePasswordReset');
    },

    clearEvaluation() {
      // We use this to clear the course evaluation back to original state
      sessionStorage.removeItem('courseEvaluation'); // Remove course evaluation
    },

    setLmsRecordsPerPage(records) {
      localStorage.setItem('lmsRecordsPerPage', JSON.stringify(records));
      self.lmsRecordsPerPage = records;
      return records;
    },

    getLmsRecordsPerPage() {
      const records = localStorage.getItem('lmsRecordsPerPage');
      return records ? JSON.parse(records) : self.setLmsRecordsPerPage(self.lmsRecordsPerPage);
    },

    getFeedback1() {
      // We use this to not show any colors in options on the lesson play
      let showFeedback1 = false;
      if (self.lessonPlay) {
        showFeedback1 = self.lessonPlay?.lesson?.attributes['1answer_nofeedback'] || false;
      }
      return showFeedback1;
    },

    setSearchParam(searchparam) {
      // We use this to set the search param whenever a user searches for a course
      self.searchParam = searchparam;
    },

    setSearchBaseProductParam(baseProductId) {
      // We use this to set the search param whenever a user searches for a course
      self.searchBaseParam = baseProductId;
    },

    setSearchSuggestedParam(suggestedparam) {
      // We use this to set the search param whenever a user searches for a course
      self.searchSuggestedParam = suggestedparam;
    },

    // [ LESSONPLAY ]
    // TODO: ❌ async -> should be FLOW function
    async getLesson(courseId, lessonId) {
      if (self.lessonPlay && self.lessonPlay.lesson.attributes.drupal_internal__nid.toString() === lessonId) return self.lesson;
      await self.fetchLessonPlay(courseId, lessonId);
      return self.lesson;
    },

    // .LESSON:OPEN POPUP WIDGET MODAL/DIALOG
    setWidgetDialogOpen(status) {
      self.dialog = status;
    },

    /**
     * $ Other actions
     */
    setRouterState(state) {
      // log.info("setRouterState: " + JSON.stringify(state));
      self.routerState = state;
    },

    gotoErrorPage(params) {
      self.errorPage = params;
      self.routerStore.goTo('errorPage');
    },

    // Merge different entities into self.included
    mergeIncludes(newEntities, overwrite = false) {
      if (!newEntities || !newEntities.forEach) return;
      newEntities.forEach((newEntity) => {
        if (!newEntity) return;
        const existingEntity = self.included.find((entity) => entity.id === newEntity.id);
        if (overwrite && existingEntity) {
          // log.info(`Found matching entities `, existingEntity.toJSON());
          destroy(existingEntity);
          self.included.push(newEntity);
        } else if (!existingEntity) {
          // log.info(`Pushing entity type ${newEntity.type} in store.included`);
          self.included.push(newEntity);
        }
      });
    },

    addIncludes(newEntity) {
      const existingEntity = self.included.find((entity) => entity.id === newEntity.id);
      if (existingEntity) {
        destroy(existingEntity);
        self.included.push(newEntity);
      } else if (!existingEntity) {
        self.included.push(newEntity);
      }
    },

    setSelectedProduct(productId, packageId) {
      self.selectedPackageId = packageId;
      self.selectedProductId = productId;
    },
    setSelectedPackage(packageId) {
      self.selectedPackageId = packageId;
    },

    // ============= SHOW MESSAGE IF DEPENDENT COURSE ==============
    setLessonDependsOnCourse(dependingCourse) {
      self.showDependingPopup = true;
      self.dependingCourse = dependingCourse;
    },

    resetDependinCourse() {
      self.showDependingPopup = false;
      self.dependingCourse = null;
    },

    // TODO: ❌ async -> should be FLOW function
    async logout(removeSaved = false) {
      self.validUser = false;
      self.password = '';
      self.remember = false;
      self.permissions = new Map();
      self.resetState();
      self.state = 'init';
      sessionStorage.removeItem('gdpr'); // Remove gdpr popup
      sessionStorage.removeItem('prefBaseProduct'); // Remove pref base
      sessionStorage.removeItem('InfoRequiredMsg'); // Remove info required message
      sessionStorage.removeItem('courseEvaluation'); // Remove course evaluation
      sessionStorage.removeItem('forcePasswordReset'); // Remove force password reset
      if (removeSaved) {
        await window.clientInstance.killSession();
      }
    },

    resetState() {
      if (self.lessonPlay) destroy(self.lessonPlay);
      if (self.products) destroy(self.products);
      if (self.invalidProducts) destroy(self.invalidProducts);
      if (self.invalidPackages) destroy(self.invalidPackages);
      if (self.subscriptions) destroy(self.subscriptions);
      if (self.user) destroy(self.user);
      if (self.included) destroy(self.included);
    },

    changeUser(newusername, newPassword, remember = false) {
      if (self.validUser) self.logout();
      self.username = newusername;
      self.password = newPassword;
      self.remember = remember;
    },

    fetchSubscriptions: flow(function* fetchSubscriptions() {
      try {
        const response = yield self.lmsApi.fetchSubscriptions();

        self.subscriptions = response.subscriptions;
        return self.subscriptions;
      } catch (e) {
        return e;
      }
    }),

    hasActiveSubscription: (productName) => {
      if (!Array.isArray(self.subscriptions?.subscriptions)) {
        return false;
      }

      if (self.subscriptions.subscriptions.length === 0) {
        return false;
      }

      if (!productName) {
        return true;
      }

      if (!Array.isArray(productName)) {
        productName = [productName];
      }

      const productNameLowerCase = productName.map((name) => name.toLowerCase());
      return (
        self.subscriptions.subscriptions.filter((sub) => productNameLowerCase.includes(sub.base_product_title.toLowerCase())).length > 0
      );
    },

    sleep: (ms = 100) => new Promise((r) => setTimeout(r, ms))
  }))

  // TOAST
  .actions((self) => ({
    resetErrorState() {
      self.state = 'done';
      self.error = null;
      self.modelErrors = [];
      self.errorPage = {};
      self.errorShown = false;
    },
    hideToast() {
      self.isToastvisible = false;
      self.toastStates.pop();
    },
    showToast(props) {
      self.toastStates.push({ ...props, isToastvisible: true });
      self.isToastvisible = true;
    }
  }))

  // MARKETINGMESSAGES
  .actions((self) => ({
    /**
     * Get the marketing
     * messages for the given category
     */
    marketingMessagesForCategory(category, position) {
      if (!self.marketing_messages || self.marketing_messages.length < 1) return false;

      const filteredMarketingMessages = self.marketing_messages.filter((marketingMessage) => {
        if (!marketingMessage.isRelatedToCategoryName(category)) return false;
        if (marketingMessage.attributes.position !== position) return false;
        return true;
      });

      if (filteredMarketingMessages.length < 1) {
        return false;
      }

      return filteredMarketingMessages;
    },

    /**
     * Get the marketing messages for
     * the given Node ID
     */
    marketingMessagesForCourseNid(courseNid, position) {
      if (!self.marketing_messages || self.marketing_messages.length < 1) return false;

      const filteredMarketingMessages = self.marketing_messages.filter((marketingMessage) => {
        if (!marketingMessage.isRelatedToCourseNid(courseNid)) return false;
        if (marketingMessage.attributes.position !== position) return false;
        return true;
      });

      if (filteredMarketingMessages.length < 1) {
        return false;
      }

      return filteredMarketingMessages;
    }
  }))

  // COURSES
  .actions((self) => ({
    setCourseEdit() {
      // if (
      //   self.courseEditState === 'done' &&
      //   self.courseEdit.course &&
      //   self.courseEdit.course.attributes.drupal_internal__nid === parseInt(self.params.courseId)
      // ) {
      //   return self.courseEdit.course.attributes.drupal_internal__nid === parseInt(self.params.courseId);
      // }
      self.courseEdit = CourseEdit.create({}, { store: self });
      return null;
    },

    // TODO: ❌ async -> should be FLOW function
    async getNewCourseEdit() {
      await self.fetchCourseEdit(false);
      return true;
    },

    // TODO: ❌ async -> should be FLOW function
    async getCourseEdit(courseId) {
      if (
        self.courseEditState === 'done' &&
        self.courseEdit.course &&
        self.courseEdit.course.attributes.drupal_internal__nid === courseId
      ) {
        return self.courseEdit.course.attributes.drupal_internal__nid === courseId;
      }
      const courseUUID = self.findCourseByNid(courseId).id;
      // let test = await self.fetchCourseEdit(courseUUID);
      await self.courseEdit.fetchCourseEdit(courseUUID);
      return self.courseEdit.course.attributes.drupal_internal__nid === courseId;
    },
    setTotalCoursesCompleted(value) {
      self.totalCompletedCourses = value;
      return self.totalCompletedCourses;
    }
  }))

  // ================== FILTERBAR / TABS =================
  .actions((self) => ({
    showFilter() {
      self.isfilterVisible = !self.isfilterVisible;
      return self.isfilterVisible;
    },
    setFilter(bool) {
      self.isfilterVisible = bool;
      return self.isfilterVisible;
    },
    hideFilter(open) {
      self.isfilterVisible = open;
      return self.isfilterVisible;
    },
    coursesLoaded(value) {
      self.loadingCourses = value;
      return self.loadingCourses;
    },
    startedCoursesLoaded(value) {
      self.startedCourse = value;
      return self.startedCourse;
    },
    // clicked packages
    tabClicked(packageId) {
      return self.clickedTabs.push(packageId);
    },
    setPrevTab(packageId) {
      self.prevTab = packageId;
      return self.prevTab;
    },
    clearTabClicked() {
      self.clickedTabs = [];
      return self.clickedTabs;
    },
    isSearching(value) {
      self.searchingCourses = value;
      return self.searchingCourses;
    }
  }))

  // LESSON EDIT
  .actions((self) => ({
    // [ LESSONS ]
    // TODO: ❌ async -> should be FLOW function
    async getLessonEdit(courseId, lessonId) {
      if (self.courseEdit.lessons && self.courseEdit.lessons.find((p) => p.attributes.drupal_internal__nid === lessonId)) {
        return self.courseEdit.lessons.find((p) => p.attributes.drupal_internal__nid === lessonId);
      }
      const courseUUID = self.findCourseByNid(courseId).id;
      await self.fetchCourseEdit(courseUUID);
      return self.courseEdit.lessons.find((p) => p.attributes.drupal_internal__nid === lessonId);
    }
  }))

  // FAQ
  .actions((self) => ({
    fetchFaqByLabel: flow(function* fetchFaqByLabel(label) {
      let fetched = null;
      try {
        fetched = yield window.authedClient.get(`feed/faq/${label}`);
        self.faq = fetched.data;
      } catch (e) {
        self.error = e;
        return null;
      }
      return Promise.resolve(fetched);
    })
  }))

  // AUTHORS
  .actions((self) => ({
    /**
     * $ AUTHORS
     * Todo: Implement for USER accounts
     */
    async postNewAuthor(newValues) {
      const author = await self.postData(newValues);

      return author.attributes.drupal_internal__nid.toString();
    },
    // .AUTHORS:UPDATE (incl upload avatar)
    async patchPerson(entity, newValues) {
      let respFileObj;

      const newAvatar = newValues.newAvatar || false;
      const hasAvatar = entity.relationships.field_photo.data || false;
      const currentAvatar = hasAvatar ? cloneDeep(getSnapshot(hasAvatar)) : false;

      const endPointField = 'field_photo';

      if (newAvatar) {
        delete newValues.newAvatar;
      }

      if (newAvatar) {
        respFileObj = await self.uploadImg(entity, newAvatar, endPointField);

        self.authorEdit.update(respFileObj, 'photos');
        self.addIncludes(respFileObj);
        if (currentAvatar) await self.deleteData(currentAvatar);

        newValues.relationships.field_photo.data = { id: respFileObj.id, type: respFileObj.type };
      }
      const respAuthorObj = await self.patchData(entity, newValues);
      self.addIncludes(respAuthorObj);
      return true;
    }
  }))

  // LMS
  .actions((self) => ({
    fetchLmsAccess: flow(function* fetchLmsAccess() {
      if (self.lmsAccess) return self.lmsAccess;
      try {
        const response = yield self.lmsApi.fetchLMSAccess();
        const { data } = response;
        self.lmsAccess = data;
      } catch (error) {
        console.error(error);
      }
      return self.lmsAccess;
    })
  }))
  .views((self) => ({
    get lmsAccessData() {
      return self.lmsAccess;
    }
  }))

  // LMS Student detail
  .actions((self) => ({
    fetchStudentDetail: flow(function* fetchStudentDetail(id, startdate, enddate) {
      const response = yield self.lmsApi.fetchLMSStudent(id, startdate, enddate);
      const { data } = response;

      self.lmsStudentDetail = data;

      return data;
    }),
    clearStudentDetail() {
      self.lmsStudentDetail = {};
      return self.lmsStudentDetail;
    }
  }))

  // LMS Course detail
  .actions((self) => ({
    fetchCourseDetail: flow(function* fetchCourseDetail(id) {
      const response = yield self.lmsApi.fetchLMSCourse(id);
      const { data } = response;

      self.lmsCourseDetail = data;

      return data;
    }),
    clearCourseDetail() {
      self.lmsCourseDetail = {};
      return self.lmsCourseDetail;
    }
  }))

  // Clear search
  .actions((self) => ({
    clearSearchParam() {
      // We use this to clear the search param whenever a user searches for a course
      self.searchParam = '';
      self.searchBaseParam = 0;
      self.searchingCourses = false;
      self.searchCoursesFound = 0;
      self.searchSuggestedParam = '';
      self.searchNextAttempt = 1;
      return self.searchingCourses;
    }
  }));

export const RootStore = t.compose(FirstStore, CurriculumStore, StoreFetch).named('RootStore');
